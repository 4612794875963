import { default } from '../../../sproutly-os-new/components/DonationModal.vue';
<template>
  <div class="input-field tw-space-y-1">
    <label v-if="label" :for="inputId" class="custom-label tw-font-medium">{{
      label
    }}</label>

    <div
      :class="[
        isFocused
          ? 'tw-border-primary-300 tw-outline-none tw-ring-2 tw-ring-primary-100'
          : '',
        error ? 'tw-border-error-500' : '',
        disabled ? 'tw-cursor-not-allowed tw-bg-gray-50' : 'tw-bg-white',
      ]"
      class="tw-flex tw-w-full tw-items-center tw-rounded-lg tw-border-2 tw-px-3 tw-text-gray-900 placeholder:tw-text-gray-400"
    >
      <Icon
        :name="leadingIcon"
        v-if="leadingIcon"
        class="tw-text-gray-500"
        size="18"
      />

      <div class="tw-flex tw-w-full tw-items-center tw-justify-between">
        <input
          :min="min"
          :max="max"
          :required="required"
          :type="type === 'password' ? inputType : type"
          :id="inputId"
          :step="step"
          :value="modelValue as string | number | object"
          :placeholder="placeholder"
          @input="$emit('update:modelValue', $event.target.value)"
          @keyup.enter="$emit('enterPressed', $event.target.value)"
          @keypress="onlyNumber"
          @blur="handleBlur"
          @focus="handleFocus"
          :pattern="pattern"
          class="tw-w-full tw-appearance-none tw-border-none tw-bg-transparent tw-p-2 tw-text-gray-900 tw-outline-none placeholder:tw-text-gray-400"
          v-bind="$attrs"
          :disabled="disabled"
          :class="[
            disabled ? 'tw-cursor-not-allowed tw-bg-gray-50' : 'tw-bg-white',
          ]"
          :autocomplete="autocomplete"
        />

        <div
          v-if="type === 'password'"
          class="tw-cursor-pointer tw-text-gray-400"
        >
          <AppIcon
            v-if="showPassword"
            name="eye-off"
            @click.prevent="togglePasswordVisibility"
          />
          <AppIcon
            v-else
            name="eye"
            @click.prevent="togglePasswordVisibility"
          />
        </div>

        <div v-if="error">
          <div class="tw-cursor-pointer tw-text-error-500">
            <Icon
              name="ci:error-outline"
              @click.prevent="toggleErrorVisibility"
            />
          </div>
        </div>

        <div v-if="loading">
          <Icon
            name="lucide:loader-circle"
            class="tw-animate-spin tw-text-primary-500"
          />
        </div>
      </div>
    </div>

    <div v-if="hint" class="tw-text-left tw-text-gray-500">
      <p>{{ hint }}</p>
    </div>

    <div v-if="error" class="tw-text-left tw-text-error-500">
      <p>{{ error }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  label: String,
  leadingIcon: String,
  placeholder: String,
  min: {
    type: String,
    default: "",
  },
  max: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "text",
  },
  pattern: String,
  required: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [Number, String, Object],
    default: "",
    required: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  allowOnlyNumber: {
    type: Boolean,
    default: false,
  },
  errors: {
    type: Object,
    default: [],
    required: false,
  },
  step: {
    type: String,
    default: "any",
  },
  errorLabel: String,
  hint: String,
  autocomplete: {
    type: String,
    default: "on",
    required: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const isFocused = ref<boolean>(false);
const showPassword = ref<boolean>(false);
const showError = ref<boolean>(false);
const emit = defineEmits([
  "modelValue",
  "update:modelValue",
  "enterPressed",
  "blur",
]);

const handleBlur = () => {
  isFocused.value = false;
  emit("blur");
};
const handleFocus = () => {
  isFocused.value = true;
};

const inputId = computed(() => {
  // Generate a unique ID for the input field
  return `input-${Math.random().toString(36).substr(2, 9)}`;
});

const inputType = computed(() => (showPassword.value ? "text" : "password"));

const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value;
};

const toggleErrorVisibility = () => {
  showError.value = !showError.value;
};

const error = computed(() => {
  let error = props.errors[props.errorLabel as string];

  // Check if error is an array before calling join
  if (Array.isArray(error)) {
    return error.join(" ");
  } else {
    return error; // Return the error as is if it's not an array
  }
});

// Only numbers
const onlyNumber = ($event: KeyboardEvent) => {
  if (props.allowOnlyNumber) {
    const keysAllowed: string[] = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ];
    const keyPressed: string = $event.key;

    if (!keysAllowed.includes(keyPressed)) {
      $event.preventDefault();
    }
  }

  return;
};
</script>
